<template>
  <div>
    <div class="popup-backdrop"></div>
    <div class="popup-wrapper rounded-3 border shadow">
      <span class="upload-popup-close-pos cursor-pointer" @click="refuseCGU()">
        <strong>X<i class="bi bi-x-lg"></i></strong>
      </span>
      <div>
        <h4 class="constituer-dossier-bold-content">{{ Welcome_Label }}</h4>

        <div class="cgu-sub-div cgu-text">
          <p>{{ Content_Text }} <a href="#" @click="seeCgu()">{{ Cgu_Link_Text }}</a>
          </p>
        </div>
      </div>
      <div class="p-3">
        <div id="v-model-radiobutton">
          <button class="button-warning-cus" type="button" @click="acceptCGU()" :disabled="buttonValidDisabled">{{ Valider_Label }} </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import {mapActions} from "vuex";

export default {
  data() {
    return {
      Welcome_Label: 'Bienvenue sur votre espace client,',
      Content_Text: 'Pour y accéder vous devez prendre connaissance et accepter',
      Cgu_Link_Text: 'les conditions générales d\'utilisation',
      picked: '',
      Valider_Label: 'J\'accepte',
      isDisabled: true,
      pdfContent: '',
      buttonValidDisabled: false
    }
  },
  props: {
    idTerm: Number
  },
  mounted() {
    this.getCurrentDocument().then((data) => {
      if (data) {
        this.pdfContent = `data:application/pdf;name=test.pdf;base64,${encodeURI(data.fileContent)}`;
      }
    });
  },
  methods: {
    ...mapActions('cgu', ['getCurrentDocument']),
    ...mapActions('client', ['setClientTermAccept']),
    acceptCGU() {
      this.buttonValidDisabled = true;
      store.commit("setDidReadCGU", true);
      this.doPostCGUStatus().then((isOk) => {
        if (isOk) {
          this.onClosePopup(true);
        }
      });
    },
    refuseCGU() {
      this.onClosePopup(false)
    },
    seeCgu() {
      let win = window.open('');
      win.document.write(`<iframe style="border: 0" width='100%' height='100%' src='${this.pdfContent}' ></iframe>`)
      win.document.body.style = "margin: 0";
    },
    /**
     * @return Promise<boolean>
     */
    async doPostCGUStatus() {
      return await this.setClientTermAccept({
        idTerm: this.idTerm
      }).then((response) => {
        return response;
      });
    },
    onClosePopup(didAccept) {
      this.$emit('closedPopup', didAccept)
    },
    enableValidateBtn() {
      this.isDisabled = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-wrapper {
  padding: 8px 16px;
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;

  label {
    display: inline;
  }
}

.popup-content {
  height: 100%;
  width: 98%;
  max-height: 100%;
}

.cgu-sub-div {
  margin-left: 50px;
  margin-right: 50px;
}

.cgu-text {
  color: #6769A8;
}

.upload-popup-close-pos {
  color: #111179;
  width: fit-content;
  align-self: end;
}
</style>
