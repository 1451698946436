<template>
    <div v-if="isConstituteFolderActive" class="border border-color-vio block rounded shadow p-3 mb-5 bg-white rounded">
        <h4 class=" text-center content-blocks-title"><strong>{{Block_Title}}</strong></h4>
        <hr />
        <p class="block-content-font text-start">{{Block_Description}}</p>
        <div
            class="border border-color-vio block rounded shadow p-3 mb-3 mt-5 bg-white rounded text-center">
            <img src="@/assets/images/dot-interro.svg" class="cmdossier-dot-icon">
            <h5 class="constituer-sub-content-title">{{ Popin_Title }}</h5>
            <p class="block-content-font"><b class="constituer-dossier-bold-content">{{Popin_Description_b}}</b> {{Popin_Description}}
            </p>
        </div>

        <div class="text-center">
            <button type="button" class="button-warning-cus to-validate-with-frost" @click="$router.push({ path:CONSTITUTE_FOLDER_PATH })">{{Block_Access_Button}}<div class="arrow"/>
            </button>
        </div>
    </div>
    <div v-else class="border border-color-vio block rounded shadow p-3 mb-5 bg-white rounded">
      <h4 class=" text-center content-blocks-title"><strong>{{Block_Title}}</strong></h4>
      <hr />
      <img :src="iconDocuImgSrc" alt="icon doc"/>
      <p class="text-center block-content-font text-start">{{Block_Description_Final}}</p>
      <div class="text-center">
        <button type="button" class="button-secondary-cus to-validate-with-frost" @click="$router.push({ path:CONSTITUTE_FOLDER_PATH })">{{Block_Access_Button_Final}}<div class="arrow"/>
        </button>
      </div>
    </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import events from '../../constants/events';
import emitter from '../../events/emitter';
import getNotifications from '../../mixins/getNotifications';
import {CONSTITUTE_FOLDER_PATH} from "../../router/pathes";

export default {
    setup() {
        const store = useStore();

        const getDocuments = computed(() => store.getters[`document/getDocuments`]);
        const getLegalDocuments = computed(() => store.getters[`document/getLegalDocuments`]);
        const getDocumentsSigning = computed(() => store.getters[`document/getDocumentsSigning`]);

        return {
            getDocuments,
            getLegalDocuments,
            getDocumentsSigning
        }
    },
    data() {
        return {
            Block_Title : "MES DOCUMENTS",
            Block_Description : "Merci de nous transmettre les pièces justificatives nécessaires à la constitution de votre dossier. " +
                "Dès que ces documents nous seront transmis, nous pourrons figer les conditions avantageuses du moment pour votre projet de financement.",
            Popin_Title : "COMMENT ?",
            Popin_Description_b : "Cliquez sur \"Envoyer mes documents\"",
            Popin_Description : "pour accéder à votre espace de téléversement.",
            Block_Access_Button : "Envoyez mes documents",
            badgeCounter: 0,
            isConstituteFolderActive : false,
            Block_Description_Final : "Retrouver vos documents importés pour la constitution de votre dossier.",
            Block_Access_Button_Final : "Consulter",
            iconDocuImgSrc: require('@/assets/images/icone_documents.svg'),
        };
    },
    methods: {
        getData() {
            this.getNotifications().then(this.whenReceiveNotifications);
        },
        /**
         * @param {Object} notifications
         */
        whenReceiveNotifications(notifications) {
            this.badgeCounter = notifications.nbDocumentsWaiting;
            this.isConstituteFolderActive = this.badgeCounter > 0;
            // emit event
            emitter.emit(events.home.constituteFolderDone);
        }
    },
    mixins: [getNotifications],
    mounted() {
      this.whenReceiveNotifications({
        nbDocumentsWaiting: this.filterDocumentsWaiting(this.getDocuments).length,
        nbDocumentsToValidate: this.reduceNbDocumentsToValidate(this.getLegalDocuments),
        nbDocumentsSigning: this.getDocumentsSigning.length
      });
      this.getData();
    },
  created() {
      this.CONSTITUTE_FOLDER_PATH = CONSTITUTE_FOLDER_PATH
  }
}
</script>
